import Q from 'q';
// 结算还款详情
import credit_billDetail from "@/lib/data-service/haolv-default/consumer_credit_billDetail.js";
// 创建还款支付单
import credit_createRefundPayment from "@/lib/data-service/haolv-default/consumer_credit_createRefundPayment.js";

export default {
  components: {
    // PaymentMethodSelector000,
  },
  data() {
    return {
      loading: false,
      detail: {},
    };
  },
  computed: {},
  methods: {
    req_detail(params) {
      if (this.loading) return;
      this.loading = true;
      credit_billDetail(params)
        .then((res) => {
          this.detail = res.datas;
          // this.init_payment()
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    init_payment() {
      const _this = this;
      const { businessContent, noPayedAmount } = _this.detail;
      //支付
      const init_000 = function() {
        _this.$refs.aPaymentMethodSelector000.init({
          // ui_show: {
          //     is_show_amount_to_be_paid: true,
          // },

          //在这里传入订单详情的相关参数
          get_params() {
            const p = {
              // companyId: ``,
              // clubId: ``,//中间社id
              orderNo: `D67885215613`, //订单编号
              businessContent, //不用管里面的值是什么，订单详情返回的businessContent,直接传入到这里
              // isUseCredit: true,//是否允许授信支付
              subject: `还款`, //
              // orderAmount: 3000,//订单金额，用于计算可以用哪些支付方式支付
              // orderAmount: 0.05,//订单金额，用于计算可以用哪些支付方式支付
              orderAmount: noPayedAmount, //订单金额，用于计算可以用哪些支付方式支付
            };
            return Q.resolve(p);
          },

          //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
          before_pay_event_handler() {
            // alert(`不能够支付！原因：XXX`);
            // return Q.reject();

            console.log(`before_pay_event_handler`);
            return Q.resolve();
          },

          //支付成功事件处理方法
          pay_successful_event_handler() {
            _this.$message.success('还款成功');
            this.$router.replace({
              name: 'admin-settlement-management-list',
            })
            console.log(`pay_successful_event_handler`);
          },

          //支付失败事件处理方法
          pay_failed_event_handler() {
            console.log(`pay_failed_event_handler`);
          },
        });
      };

      init_000();
    },
  },
  filters: {},
  created() {},
  activated() {
    this.req_detail({ billId: this.$route.query.billId });
  },
  watch: {},
};
